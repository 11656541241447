
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class About extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
  }
}
