<template>
  <div class="about">
    <div class="about-content" style="padding: 3rem 0rem 0rem">
      <div class="about-container container is-widescreen" style="padding: 3rem 0rem 0rem">
        <div class="about-container-title">
          株式会社if について
        </div>
        <div class="columns about-container-info">
          <div class="column is-half about-container-info-left">
            株式会社ifは創業以来、人と技術を通して、地域社会から信用・信頼される企業を目指してまいりました。現在においてもその原点を忘れることなく、人の暮らしを支える 安心と信頼 の企業でありたいと願い、日々弛まぬ努力を重ねてまいります。 
          </div>
          <img class="column is-half about-container-info-right" src="./../assets/IMG_1543_4.jpg">
        </div>
        <div class="corporate-philosophy">
          <div class="corporate-philosophy-header">
            企業理念
          </div>
          <div class="corporate-philosophy-content">
            <div class="corporate-philosophy-content-main">
              快適、安心、敏速を皆様に
            </div>
            <div class="corporate-philosophy-content-details" style="white-space: pre-wrap;word-wrap:break-word;">
              {{"安心・納得のいただけるサービスを常に追求し、\n企業の発展と豊かな地域づくりに貢献します。"}}
            </div>
          </div>
        </div>
        <div class="about-table-content">
          <div
            style="
              font-weight: 500;
              font-size: 1.25em;
              text-align: left;
              color: #2e3192;
              padding: 3rem 10px 3rem;
            "
          >
            会社概要
          </div>
          <table>
            <tr>
              <th scope="row">会社名</th>
              <td>
                <div>rehome if Inc.</div>
                <div>株式会社イフ</div>
              </td>
            </tr>
            <tr>
              <th scope="row">所在地</th>
              <td>長野県須坂市須坂１２９８−１３</td>
            </tr>
            <tr>
              <th scope="row">代表取締役</th>
              <td>宮島 和利</td>
            </tr>
            <tr>
              <th scope="row">設立</th>
              <td>２０１４年１０月１０日</td>
            </tr>
            <tr>
              <th scope="row">資本金</th>
              <td>３００万円</td>
            </tr>
            <tr>
              <th scope="row">従業員数</th>
              <td>１２人</td>
            </tr>
            <tr>
              <th scope="row">事業内容</th>
              <td>
                リフォーム業全般、ハウスクリーニング、内外塗装、外構工事、造園工事他
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class About extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
  }
}
</script>
<style lang="scss">
@import "@/assets/util.scss";

.corporate-philosophy {

  padding: 3rem 1.5rem 5rem;
  background-color: #f5f5f5;

  .corporate-philosophy-header {
    font-weight: 500;
    font-size: 1.25em;
    text-align: left;
    color: #2e3192;
    padding: 3rem 10px 3rem;
  }

  .corporate-philosophy-content {
    text-align: center;
    color: #2e3192;
    font-family:'Shippori Mincho', serif;

    .corporate-philosophy-content-main {
      font-weight: 600;
      font-size: 40px;
      padding: 1rem 1.5rem 1rem;
    }

    .corporate-philosophy-content-details {
      font-size: 24px;
    }

    // @include tablet {
    //   .corporate-philosophy-content-main {
    //     font-weight: 600;
    //     font-size: 5vw;
    //     padding: 1rem 1rem 1rem;
    //   }

    //   .corporate-philosophy-content-details {
    //     font-size: .825rem;
    //     padding: 0rem 1rem 1rem;
    //   }
    // }

    @include sp {
      .corporate-philosophy-content-main {
        font-weight: 600;
        font-size: 6.5vw;
        padding: 1rem .5rem 1rem;
      }

      .corporate-philosophy-content-details {
        font-size: 3.5vw;
      }
    }
  }


}

.about {
  padding-top: 3.25rem;

  .about-company {
    background-image: url("./../assets/IMG_1543.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: scale(1.2);
    // height: 25vh;
  }

  .about-table-content {
    padding: 3rem 1.5rem 3rem;

    td {
      // border: 1px solid #2e319279;
      padding: 10px;
      width: 70%;
      color: #2e3192;
    }

    th {
      // border: 1px solid #2e319279;
      padding: 10px;
      padding-bottom: 20px;
      width: 30%;
      color: #2e3192;
      font-weight: 300;
      text-align: left;
    }

    table {
      // border: 1px solid #2e3192;
      // border-collapse: collapse;
      width: 100%;
    }
  }

  @include sp {
    .about-table-content {
      padding: 3rem 1.5rem 3rem;
    }
  }
}

.about-container {
  &-title {
    font-weight: 700;
    font-size: 1.75em;
    text-align: center;
    color: #2e3192;
    padding: 1.25rem;
  }

  &-info {
    margin: 0px;
    align-items:center;

    &-left {
      font-weight: 400;
      font-size: 1.25rem;
      text-align: left;
      color: #2e3192;
      padding: 2rem;
    }

    @include sp {
      &-left {
        font-weight: 400;
        font-size: calc(2.5vw + 30%);
        text-align: left;
        color: #2e3192;
        padding: 2rem;
      }
    }
  }
}
</style>
